<template>
    <v-container fluid>
        <v-card outlined elevation="5">
            <v-card-title class="primary--text card-header">
                {{this.title}}
                <v-spacer/>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                :loading="loading"
                                :disabled="loading"
                                v-on="on"
                                small
                                fab
                                icon
                                @click="edit"
                                dark
                                color="white"
                        >
                            <v-icon color="primary" dark>save</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('generic.lang_edit')}}</span>
                </v-tooltip>

            </v-card-title>
            <v-divider class="ma-0 pa-0"/>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row
                            :class="(!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)?  'pt-12 pr-12 pl-12' : ''"
                    >
                        <v-col cols="12" sm="6">
                            <v-text-field dense
                                    prepend-inner-icon="storage"
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :label="this.$t('erp.lang_storageName')"
                                    v-model="storage.storageName"
                                    :rules="[
                    v => !!v || $t('generic.lang_required')
                ]"
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field dense
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    prepend-inner-icon="email"
                                    :label="this.$t('generic.lang_emailAdresse')"
                                    v-model="storage.storageEMail"
                                    type="email"
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field dense
                                    prepend-inner-icon="flag"
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :label="this.$t('generic.lang_country')"
                                    v-model="storage.storageCountry"
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field dense outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          prepend-inner-icon="location_city"
                                          :label="this.$t('settings.lang_city')"
                                          v-model="storage.storageCity"

                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field dense
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    prepend-inner-icon="streetview"
                                    :label="this.$t('generic.lang_street')"
                                    v-model="storage.storageStreet"

                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field dense
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    prepend-inner-icon="home"
                                    :label="this.$t('generic.lang_zip')"
                                    v-model="storage.storageZip"

                            />
                        </v-col>

                        <v-col cols="12" sm="6" class="pb-0 mb-0">
                            <v-text-field dense
                                    outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    prepend-inner-icon="smartphone"
                                    :label="this.$t('generic.lang_phoneNumber')"
                                    v-model="storage.storagePhone"

                            />
                        </v-col>

                      <v-col cols="12" sm="6" class="pb-0 mb-0">
                        <v-text-field dense
                                      outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      prepend-inner-icon="local_phone"
                                      :label="this.$t('generic.lang_fax')"
                                      v-model="storage.storageFax"

                        />
                      </v-col>

                      <v-col cols="12" sm="6" class="pb-0 mb-0">
                        <users :label="this.$t('generic.lang_selectUser')" dense icon="person" outlined v-bind:user="storage.user_id"
                               v-model="storage.user_id"
                               :rules="[v => !!v || $t('generic.lang_required')]"/>
                      </v-col>

                      <v-col cols="12" sm="6">
                            <v-select v-model="storage.cashierId"
                                        :items="filteredCashierIDs"
                                        item-text="name"
                                        item-value="value"
                                        :label="$t('generic.lang_cashierID')"
                                        outlined
                                        dense
                                        disabled
                            ></v-select>
                       </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard
                    id="onScreenKeyboard"
                    class="internalWidthExpanded"
                    :options="touchKeyboard.options"
                    :defaultKeySet="touchKeyboard.keySet"
                    v-if="touchKeyboard.visible"
                    :layout="touchKeyboard.layout"
                    :cancel="hideTouchKeyboard"
                    :accept="hideTouchKeyboard"
                    :input="touchKeyboard.input"
            />
        </div>
    </v-container>
</template>

<script>
    //configd
    import {Events} from "../../../../plugins/events";
    import {ENDPOINTS} from "../../../../config";
    import mixin from "../../../../mixins/KeyboardMixIns";
    import { mapGetters } from 'vuex';
    import Users from "../../../common/Users";
    export default {
      components:{
        Users
      },
        data: () => {
            return {
                storage: {
                  user_id: null
                },
                ENDPOINT: ENDPOINTS,
                loading: false,
                title: ""
            };
        },
        mixins: [mixin],
        computed: {
            ...mapGetters({
                cashierIDs: "cashierIDs/cashiers",
            }),
            filteredCashierIDs() {
                return this.cashierIDs.map(item => ({
                    name: this.$t('generic.lang_kasse') + ' ' + item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
                    value: `${item.id}`
                }))
            },
        },
        mounted() {
           this.loadStorage();
        },
        methods: {
            async loadStorage() {
                //this.$store.commit("loader/setLoader", true);
                await this.axios
                    .post(this.ENDPOINT.ERP.STORAGEOVERVIEW.SHOW, {
                        storageID: this.$route.params.id
                    })
                    .then(result => {
                        //this.$store.commit("loader/setLoader", false);
                        this.storage = result.data.storageInformation;
                        this.title = this.storage.storageName;
                    })
                    .catch(err => {
                        //this.$store.commit("loader/setLoader", false);
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error"
                        });
                    });
            },
            edit() {
                if (!this.$refs.form.validate()) return;
                this.loading = true;
                this.axios
                    .post(this.ENDPOINT.ERP.STORAGEOVERVIEW.UPDATE, this.storage)
                    .then(result => {
                        this.loading = false;
                        this.title = this.storage.storageName;
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_actionSuccessful"),
                            color: "success"
                        });
                    })
                    .catch(err => {
                        this.loading = false;
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error"
                        });
                    });
            }
        }
    };
</script>
